import SportsGravyLogoIcon from "@assets/icons/sportsgravy.svg";
import SportsGravyLogo from "@assets/images/sg-logo-bw.svg";
import {
  CannedIcon,
  DashboardIcon,
  GameConceptIcon,
  GameSystemIcon,
  ImportTmpIcon,
  LevelIcon,
  OrganizationIcon,
  PositionIcon,
  RoleIcon,
  SeasonsIcon,
  SettingIcon,
  SkillIcon,
  SportIcon,
  SubRoleIcon,
  UserIcon,
  TeamsIcon,
  TrainingProgramsIcon,
  FeedIcon,
  ReportIcon,
  ImportIcon,
  LiveStreamIcon,
  SponsorshipLevelIcon,
  SportLocationIcon,
  CalendarIcon,
  SupportIcon,
  QaIcon,
  TestcaseIcon,
  GanttChartIcon
} from "@components/Icons";
import { NotificationDrawer } from "@components/NotificationDrawer";
import { SearchInput } from "@components/SearchInput";
import {
  EdgeSidebar,
  Header,
  Root,
  Scheme,
  SidebarContent
} from "@mui-treasury/layout";
import { AccountCircleOutlined, Logout } from "@mui/icons-material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  accessTokenAtom,
  organizationsAtom,
  organizationAtom,
  profileAtom,
  previousPathAtom
} from "@recoil/auth";
import { canAccessDashboard, hasPermission } from "@services/Casbin";
import { getUnreadNotificationCount } from "@services/Network";
import {
  OrganizationType,
  useAdminCalendarGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import React, { useEffect, useState } from "react";
import {
  Location,
  NavigateFunction,
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
const StyledAppBar = styled(AppBar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.palette.white.main};
  border: solid ${(props) => props.theme.palette.grey[200]};
  border-width: 0px 0px 1px 0px;
  box-shadow: unset;
  height: 60px;
  box-shadow: 0 1.5px 2px 0 ${(props) => props.theme.palette.grey[200]};
  padding: 10px 14px;
`;

// const StyledIconButton = styled(IconButton)(() => ({
//   marginLeft: "32px",
//   fontSize: "12px",
//   width: "40pxx",
//   display: "flex",
//   justifyContent: "space-between",

//   "& .create-btn-text": {
//     fontSize: "14px",
//     whiteSpace: "nowrap",
//   },

//   " .MuiPaperRoot.MuiList": {
//     width: "3000px",
//   },
// }));

const SelectContainer = styled(Box)`
  width: 482px;
  height: 48px;
  margin-right: 20px;
`;

const NavbarItemsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifySelf: "flex-end"
}));

const NotificationContainer = styled(Box)(() => ({
  marginRight: "24px",
  display: "flex",
  alignItems: "center"
}));

const StyledSidebarContent = styled(SidebarContent)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.white.main
}));

const SideBarContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 18px 2px 18px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 5px;
    border: 1px solid #f1f5f980;
  }
`;

const Logo = styled("img")`
  cursor: pointer;
  width: 143px;
  display: block;
  margin: 0 auto;
`;

const LogoIcon = styled("img")`
  cursor: pointer;
  width: 36px;
`;

const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: calc(100vh - 60px);
  margin-left: 256px;
  flex: 1;
  background-color: ${(props) => props.theme.palette.white.main};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  &.collapsed {
    margin-left: 68px;
    width: calc(100vw - 68px);
  }
`;

const StyledListCategory = styled("div")`
  color: #cacaca;
  text-transform: uppercase;
`;

const StyledList = styled(List)``;
const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  margin: 0,
  padding: ".2rem .25rem .4rem .5rem",
  borderRadius: ".3rem",
  "&.Mui-selected": {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.white.main
  },
  "&.collapsed": {
    padding: ".25rem .25rem",
    margin: ".25rem 0"
  },
  "& .MuiTypography-root": {
    fontWeight: 600,
    color: theme.palette.white.main,
    textTransform: "capitalize"
  }
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: "0",
  margin: ".5rem 0",

  "&.main-item": {
    marginTop: "1rem",
    fontWeight: 600,

    "& .MuiTypography-root": {
      fontWeight: 600
    }
  },

  "&.sub-item": {
    "&.collapsed": {
      visibility: "hidden",
      display: "none"
    },

    "& .MuiTypography-root": {
      fontWeight: 600,
      color: theme.palette.white.main
    }
  }
}));

const StyledListItemIcon = styled(ListItemIcon)`
  width: 16px;
  height: 17px;
  padding: 0;
  margin-right: 0.75rem;
  min-width: 0;
  color: transparent;

  &.collapsed {
    width: 24px;
    height: 24px;
    margin-right: 0;
  }
`;

const SidebarFooter = styled("div")`
  justify-self: flex-end;
  margin-top: auto;
  margin-bottom: 1rem;
  padding: 0 1rem;
  text-align: center;
`;
const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    color: "#0D9488",
    backgroundColor: "#0D9488"
  }
}));

const NavMainItem = ({
  collapsed,
  icon,
  text,
  variant
}: {
  collapsed?: boolean;
  icon?: React.ReactNode;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variant?: any;
}) => {
  if (collapsed) {
    return (
      <Divider
        sx={{
          width: "80%",
          margin: "0 auto"
        }}
      />
    );
  }
  return (
    <StyledListItem className="main-item">
      {!!icon && (
        <StyledListItemIcon
          aria-label="menu"
          className={`${collapsed ? "collapsed" : ""}`}
          color={variant}
        >
          {icon}
        </StyledListItemIcon>
      )}
      <ListItemText>
        <Typography variant={variant}>{text}</Typography>
      </ListItemText>
    </StyledListItem>
  );
};

const NavSubItem = ({
  collapsed,
  icon,
  text,
  href,
  navigate,
  location,
  testid
}: {
  collapsed?: boolean;
  icon?: React.ReactNode;
  text: string;
  href: string;
  navigate: NavigateFunction;
  location: Location;
  testid?: string;
}) => (
  <StyledListItemButton
    data-testid={testid}
    selected={location?.pathname?.startsWith(href)}
    onClick={() => navigate?.(href)}
    className={`sub-item${collapsed ? " collapsed" : ""}`}
  >
    {!!icon && (
      <StyledListItemIcon
        aria-label="menu"
        className={`${collapsed ? "collapsed" : ""}`}
      >
        {icon}
      </StyledListItemIcon>
    )}
    {!collapsed && (
      <ListItemText
        sx={{
          padding: "4px 0 0 8px"
        }}
      >
        <Typography variant="body1">{text}</Typography>
      </ListItemText>
    )}
  </StyledListItemButton>
);

const scheme = {
  header: {
    config: {
      xs: { position: "sticky", height: 60 },
      md: { position: "sticky", height: 60 }
    }
  },
  leftEdgeSidebar: {
    config: {
      xs: {
        variant: "permanent",
        width: 256,
        collapsible: true,
        collapsedWidth: 68
      }
    },
    autoCollapse: "sm"
  }
} as Scheme;

const adminMenu = [
  {
    text: "General",
    items: [
      {
        text: "Dashboard",
        href: "/dashboard",
        icon: <DashboardIcon />,
        permissionId: "general.dashboard",
        action: "VIEW"
      },
      {
        text: "Organizations",
        href: "/organizations",
        icon: <OrganizationIcon />,
        permissionId: "general.organization",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Live Streaming",
    items: [
      {
        text: "Live Stream",
        href: "/live-stream",
        icon: <LiveStreamIcon />,
        permissionId: ["organization.post"],
        action: "ON"
      },
      {
        text: "Advertisers",
        href: "/advertisers",
        icon: <SponsorshipLevelIcon />,
        permissionId: ["live-streaming.advertisers"],
        action: "EDIT"
      },
      {
        text: "Sponsorship Levels",
        href: "/sponsorship-levels",
        icon: <SponsorshipLevelIcon />,
        permissionId: ["live-streaming.sponsors"],
        action: "EDIT"
      }
    ]
  },
  {
    text: "Technology Department",
    items: [
      {
        text: "Features",
        href: "/features",
        icon: <TestcaseIcon />,
        permissionId: "tech.features",
        action: "VIEW"
      },
      {
        text: "Test Cases",
        href: "/testcases",
        icon: <TestcaseIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      },
      {
        text: "Gantt Chart",
        href: "/gantt-chart",
        icon: <GanttChartIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      },
      {
        text: "QA Releases",
        href: "/releases",
        icon: <QaIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      },
      {
        text: "QA Features",
        href: "/qa-features",
        icon: <QaIcon />,
        permissionId: "tech.features",
        action: "VIEW"
      },
      {
        text: "QA Test Cases",
        href: "/qa-testcases",
        icon: <QaIcon />,
        permissionId: "tech.test-cases",
        action: "VIEW"
      }
    ]
  },
  {
    text: "Human Resources Department",
    items: [
      {
        text: "Departments",
        href: "/departments",
        icon: <OrganizationIcon />,
        permissionId: "hr.departments",
        action: "EDIT"
      },
      {
        text: "Job Titles",
        href: "/job-titles",
        icon: <OrganizationIcon />,
        permissionId: "hr.job-titles",
        action: "EDIT"
      },
      {
        text: "Company Directory",
        href: "/directory",
        icon: <OrganizationIcon />,
        permissionId: "tech.test-cases",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Feed Management",
    items: [
      {
        text: "Feed",
        href: "/feed",
        icon: <FeedIcon />,
        permissionId: "organization.post",
        action: "ON"
      },
      {
        text: "Reports",
        href: "/reports",
        icon: <ReportIcon />,
        permissionId: "organization.post",
        action: "ON"
      }
    ]
  },
  {
    text: "User Management",
    items: [
      {
        text: "Users",
        href: "/users",
        icon: <UserIcon />,
        permissionId: "user.users",
        action: "EDIT"
      },
      {
        text: "Roles",
        href: "/roles",
        icon: <RoleIcon />,
        permissionId: "user.roles",
        action: "EDIT"
      },
      {
        text: "Sub Roles",
        href: "/subroles",
        icon: <SubRoleIcon />,
        permissionId: "user.sub-roles",
        action: "EDIT"
      },
      {
        text: "Import Template Providers",
        href: "/import-template-providers",
        icon: <ImportTmpIcon />,
        permissionId: "user.import-providers",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Support",
    items: [
      {
        text: "Help Categories",
        href: "/help-category",
        icon: <SupportIcon />,
        permissionId: "support.categories",
        action: "EDIT"
      },
      {
        text: "Help Articles",
        href: "/help-articles",
        icon: <SupportIcon />,
        permissionId: "support.articles",
        action: "EDIT"
      },
      {
        text: "FAQ’s",
        href: "/faqs",
        icon: <SupportIcon />,
        permissionId: "support.faqs",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Setup",
    items: [
      {
        text: "Sports",
        href: "/sports",
        icon: <SportIcon />,
        permissionId: "admin.sports",
        action: "EDIT"
      },
      {
        text: "Positions",
        href: "/positions",
        icon: <PositionIcon />,
        permissionId: "admin.positions",
        action: "EDIT"
      },
      {
        text: "Levels",
        href: "/levels",
        icon: <LevelIcon />,
        permissionId: "admin.levels",
        action: "EDIT"
      },
      {
        text: "Skills",
        href: "/skills",
        icon: <SkillIcon />,
        permissionId: "admin.skills",
        action: "EDIT"
      },
      {
        text: "Game Concepts",
        href: "/game-concepts",
        icon: <GameConceptIcon />,
        permissionId: "admin.game-concepts",
        action: "EDIT"
      },
      {
        text: "Game Systems",
        href: "/game-systems",
        icon: <GameSystemIcon />,
        permissionId: "admin.game-systems",
        action: "EDIT"
      },
      {
        text: "Settings",
        href: "/settings",
        icon: <SettingIcon />,
        permissionId: "admin.settings",
        action: "EDIT"
      }
    ]
  }
];

const orgMenu = [
  {
    text: "General",
    items: [
      {
        text: "Dashboard",
        href: "/dashboard",
        icon: <DashboardIcon />,
        permissionId: "general.dashboard",
        action: "VIEW"
      },
      {
        text: "Calendar",
        href: "/calendar",
        icon: <CalendarIcon />,
        permissionId: "general.calendar",
        action: "VIEW"
      },
      {
        text: "Organization Details",
        href: "/organizations",
        icon: <OrganizationIcon />,
        permissionId: "organization.organization",
        action: "EDIT"
      },
      {
        text: "Seasons",
        href: "/seasons",
        icon: <SeasonsIcon />,
        permissionId: "general.seasons",
        action: "EDIT"
      },
      {
        text: "Teams",
        href: "/teams",
        icon: <TeamsIcon />,
        permissionId: "team.teams",
        action: "EDIT"
      },
      {
        text: "Training Programs",
        href: "/training-programs",
        icon: <TrainingProgramsIcon />,
        permissionId: "training-programs.training-programs",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Live Streaming",
    items: [
      {
        text: "Live Stream",
        href: "/live-stream",
        icon: <LiveStreamIcon />,
        permissionId: [
          "organization.post",
          "team.all-social",
          "training-programs.all-social"
        ],
        action: "ON"
      },
      {
        text: "Advertisers",
        href: "/advertisers",
        icon: <SponsorshipLevelIcon />,
        permissionId: "live-streaming.advertisers",
        action: "EDIT"
      },
      {
        text: "Sponsorship Levels",
        href: "/sponsorship-levels",
        icon: <SponsorshipLevelIcon />,
        permissionId: "live-streaming.sponsors",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Feed Management",
    items: [
      {
        text: "Feed",
        href: "/feed",
        icon: <FeedIcon />,
        permissionId: "organization.post",
        action: "ON"
      },
      {
        text: "Canned Messages",
        href: "/canned-messages",
        icon: <CannedIcon />,
        permissionId: "feed.canned-messaged",
        action: "EDIT"
      },
      {
        text: "Reports",
        href: "/reports",
        icon: <ReportIcon />,
        permissionId: "organization.post",
        action: "ON"
      }
    ]
  },
  {
    text: "User Management",
    items: [
      {
        text: "Users",
        href: "/users",
        icon: <UserIcon />,
        permissionId: "user.users",
        action: "EDIT"
      },
      {
        text: "Upload Players / Athletes",
        href: "/import-players",
        permissionId: "user.upload",
        icon: <ImportIcon />,
        action: "EDIT"
      },
      {
        text: "Roles",
        href: "/roles",
        icon: <RoleIcon />,
        permissionId: "user.roles",
        action: "EDIT"
      },
      {
        text: "Sub Roles",
        href: "/subroles",
        icon: <SubRoleIcon />,
        permissionId: "user.sub-roles",
        action: "EDIT"
      }
    ]
  },
  {
    text: "Setup",
    items: [
      {
        text: "Sports Locations",
        href: "/sport-locations",
        icon: <SportLocationIcon />,
        permissionId: "general.calendar",
        action: "EDIT"
      },
      {
        text: "Levels",
        href: "/levels",
        icon: <LevelIcon />,
        permissionId: "admin.levels",
        action: "EDIT"
      },
      {
        text: "Skills",
        href: "/skills",
        icon: <SkillIcon />,
        permissionId: "admin.skills",
        action: "EDIT"
      },
      {
        text: "Game Concepts",
        href: "/game-concepts",
        icon: <GameConceptIcon />,
        permissionId: "admin.game-concepts",
        action: "EDIT"
      },
      {
        text: "Game Systems",
        href: "/game-systems",
        icon: <GameSystemIcon />,
        permissionId: "admin.game-systems",
        action: "EDIT"
      },
      {
        text: "Settings",
        href: "/settings",
        icon: <SettingIcon />,
        permissionId: "admin.settings",
        action: "EDIT"
      }
    ]
  }
];

export const AuthenticatedTemplate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = useRecoilValue(accessTokenAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const [organization, setOrganization] = useRecoilState(organizationAtom);
  const setPreviousPath = useSetRecoilState(previousPathAtom);
  const [menu, setMenu] = useState<typeof adminMenu | typeof orgMenu>([]);
  const profile = useRecoilValue(profileAtom);
  useEffect(() => {
    if (!accessToken) {
      navigate("/", {
        state: { hasTimedOut: true }
      });
    }
  }, [accessToken]);
  useEffect(() => {
    canAccessDashboard()
      .then((hasAccess) => {
        if (!hasAccess) {
          return navigate("/unauthorized");
        }
      })
      .catch(() => {
        navigate("/");
      });
  }, [accessToken, navigate]);

  useEffect(() => {
    setPreviousPath(location.pathname);
  }, [location.pathname, setPreviousPath]);

  useEffect(() => {
    (async () => {
      if (!organization) {
        // return setMenu(adminMenu);
        const menu: typeof adminMenu = [];
        for (const menuGroup of adminMenu) {
          const filteredItems: typeof menuGroup.items = [];
          for (const item of menuGroup.items) {
            const can = await hasPermission(
              "SYSTEM",
              "*",
              item.permissionId as string,
              item.action as string
            );

            if (can) {
              filteredItems.push(item);
            }
          }
          if (filteredItems.length > 0) {
            menu.push({
              ...menuGroup,
              items: filteredItems
            });
          }
        }
        return setMenu(menu);
      }

      const modelOrg = organizations.find(
        (org) => org.organizationId === organization
      );
      if (!modelOrg) {
        return;
      }
      // filter orgMenu based on permissionId and action
      const menu: typeof orgMenu = [];
      for (const menuGroup of orgMenu) {
        const filteredItems: typeof menuGroup.items = [];
        for (const item of menuGroup.items) {
          if (
            (item.permissionId === "admin.seasons" ||
              item.permissionId === "team.teams") &&
            modelOrg?.offering === "TRAINING_PROGRAMS"
          )
            continue;
          if (
            item.permissionId === "training-programs.training-programs" &&
            modelOrg?.offering === "TEAMS"
          )
            continue;
          if (
            (item.permissionId === "admin.skills" ||
              item.permissionId === "admin.game-concepts" ||
              item.permissionId === "admin.game-systems") &&
            !(
              [
                OrganizationType.NATIONAL_GOVERNING_BODY,
                OrganizationType.STATE_GOVERNING_BODY
              ] as OrganizationType[]
            ).includes(modelOrg.type!)
          )
            continue;
          if (Array.isArray(item.permissionId)) {
            const can = await Promise.all(
              item.permissionId.map((permissionId) =>
                hasPermission(
                  "ORGANIZATION",
                  organization!,
                  permissionId as string,
                  item.action as string
                )
              )
            );
            if (can.some((c) => c)) {
              filteredItems.push(item);
            }
          } else {
            const can = await hasPermission(
              "ORGANIZATION",
              organization!,
              item.permissionId as string,
              item.action as string
            );
            if (can) {
              filteredItems.push(item);
            }
          }
        }
        if (filteredItems.length > 0) {
          menu.push({
            ...menuGroup,
            items: filteredItems
          });
        }
      }

      setMenu(menu);
    })();
  }, [organization]);

  const startDate = new Date();
  startDate.setUTCDate(1);
  startDate.setUTCHours(0, 0, 0, 0);
  startDate.setUTCMonth(startDate.getUTCMonth() - 4);

  const endDate = new Date();
  endDate.setUTCHours(23, 59, 59, 0);
  endDate.setUTCMonth(endDate.getUTCMonth() + 4);
  useAdminCalendarGet(
    {
      organizationId: organization!,
      startDate: encodeURI(startDate.toISOString()),
      endDate: encodeURI(endDate.toISOString())
    },
    {
      query: {
        queryKey: ["CalendarEvents", organization]
      },
      staleTime: 15 * 60 * 1000
    }
  );

  const unreadCountGet = getUnreadNotificationCount(
    {
      ...(organization && { organizationId: organization })
    },
    {}
  );

  const { unreadCount } = unreadCountGet;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAvatar = () => {
    setAnchorEl(null);
  };
  const handleProfileClick = () => {
    navigate("/profile");
    handleCloseAvatar();
  };
  const handleSignOutClick = () => {
    localStorage.clear();
    navigate("/");
    handleCloseAvatar();
  };
  const getRole = () => {
    const priorityOrder = {
      ADMIN: 1,
      COACH: 2,
      MANAGER: 3,
      PARENT: 4,
      PLAYER: 5
    };
    if (profile?.roles) {
      const roles = profile.roles
        ?.filter((role) =>
          !organization
            ? role.organization === null && role.role?.type === "SYSTEM"
            : role.organizationId === organization
        )
        .map((role) => ({
          name: role.role?.name,
          alias: role.role?.alias,
          priority: role.role?.alias ? priorityOrder[role.role.alias] : 0
        }));
      if (roles) {
        const highestPrioirtyRole = roles.reduce((min, current) => {
          return current.priority < min.priority ? current : min;
        }, roles[0]);
        return highestPrioirtyRole.name;
      }
    }
  };

  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] =
    useState(false);
  const toggleNotificationDrawer = (isOpen) => {
    setIsNotificationDrawerOpen(isOpen);
    if (!isOpen) {
      unreadCountGet.refetch();
    }
  };

  const [orgSelectorInputValue, setOrgSelectorInputValue] =
    useState<string>("");

  useEffect(() => {
    if (orgSelectorInputValue === "") {
      const org = organizations.find((o) =>
        organization
          ? o.organizationId === organization
          : o.organizationId === undefined
      );
      setOrgSelectorInputValue(org?.name || "");
    }
  }, [organization]);
  return (
    <Root scheme={scheme}>
      {({ state }) => (
        <>
          <Header sx={{ paddingRight: "0px !important" }}>
            <StyledAppBar position="static">
              <NavbarItemsContainer>
                <SelectContainer data-testid="organization-select">
                  {organizations && (
                    <Autocomplete
                      clearOnBlur={false}
                      options={organizations.map((org) => ({
                        value: org.organizationId,
                        label: org.name as string
                      }))}
                      inputValue={orgSelectorInputValue}
                      renderInput={(params) => {
                        return (
                          <SearchInput
                            data-testid="SEARCH_ORGANIZATION_INPUT"
                            {...params}
                            value={orgSelectorInputValue}
                            onChange={(e) => {
                              setOrgSelectorInputValue(e.target.value);
                            }}
                            onBlur={() => {
                              const org = organizations.find((org) =>
                                organization
                                  ? org.organizationId === organization
                                  : org.organizationId === undefined
                              );
                              setOrgSelectorInputValue(org?.name || "");
                            }}
                            placeholder="Search for Organizations"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password" // disable autocomplete and autofill
                            }}
                          />
                        );
                      }}
                      renderOption={(
                        props,
                        option: { value: string | undefined; label: string }
                      ) => {
                        return (
                          <MenuItem
                            data-testId={`ORG_OPTION_${option.value}`}
                            key={option.value}
                            id={option.value}
                            selected={
                              organization
                                ? option.value === organization
                                : option.value === undefined
                            }
                            disabled={
                              organization
                                ? option.value === organization
                                : option.value === undefined
                            }
                            onClick={() => {
                              setOrgSelectorInputValue(option.label);
                              if (option.label === "SportsGravy LLC") {
                                setOrganization(undefined);
                              } else {
                                setOrganization(option.value);
                                setRecoil(organizationAtom, option.value);
                              }
                              navigate("/dashboard");
                            }}
                          >
                            <ListItemText>{option.label}</ListItemText>
                          </MenuItem>
                        );
                      }}
                    />
                  )}
                </SelectContainer>
                <NotificationContainer data-testid="notification">
                  <IconButton
                    onClick={() => {
                      toggleNotificationDrawer(true);
                    }}
                  >
                    <StyledBadge
                      badgeContent={
                        <Typography
                          style={{ color: "white", fontSize: "13px" }}
                        >
                          {unreadCount}
                        </Typography>
                      }
                    >
                      <NotificationsNoneIcon className="notification-bell" />
                    </StyledBadge>
                  </IconButton>
                </NotificationContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end"
                  }}
                >
                  <Typography variant="formLabel">
                    {formatFullName(profile?.person)}
                  </Typography>
                  <Typography style={{ color: "#8D8D8D", fontSize: "12px" }}>
                    {getRole()}
                  </Typography>
                </div>
                <IconButton
                  data-testid="menu-avatar"
                  onClick={handleClickAvatar}
                >
                  <Avatar
                    alt="Avatar"
                    src={
                      profile?.person?.avatarId
                        ? profile?.person?.avatar?.baseUrl +
                          profile?.person?.avatar?.path
                        : ""
                    }
                  />
                </IconButton>
              </NavbarItemsContainer>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                disableScrollLock={true}
                open={open}
                onClose={handleCloseAvatar}
                onClick={handleCloseAvatar}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              >
                <MenuItem onClick={handleProfileClick}>
                  <ListItemIcon>
                    <AccountCircleOutlined
                      fontSize="medium"
                      htmlColor="#64748B"
                    />
                  </ListItemIcon>
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleSignOutClick}>
                  <ListItemIcon>
                    <Logout fontSize="medium" htmlColor="#64748B" />
                  </ListItemIcon>
                  Sign Out
                </MenuItem>
              </Menu>
              {isNotificationDrawerOpen && (
                <NotificationDrawer
                  isNotificationDrawerOpen={isNotificationDrawerOpen}
                  toggleNotificationDrawer={toggleNotificationDrawer}
                />
              )}
            </StyledAppBar>
          </Header>
          <EdgeSidebar anchor="left">
            <StyledSidebarContent>
              <SideBarContentContainer data-testid="sidemenu">
                {state?.leftEdgeSidebar?.collapsed ? (
                  <LogoIcon src={SportsGravyLogoIcon} alt="SG" />
                ) : (
                  <Logo src={SportsGravyLogo} alt="SportsGravy" />
                )}
                <StyledList>
                  {menu.map((item) => (
                    <StyledListCategory key={item.text} data-testid={item.text}>
                      <NavMainItem
                        key={item.text}
                        collapsed={state?.leftEdgeSidebar?.collapsed}
                        text={item.text}
                        variant="navigationMenuHeader"
                      />
                      {item.items.map((subItem, i) => (
                        <NavSubItem
                          testid={subItem.permissionId}
                          key={`${item.text}-${i}`}
                          collapsed={state?.leftEdgeSidebar?.collapsed}
                          icon={subItem.icon}
                          text={subItem.text}
                          href={subItem.href}
                          navigate={navigate}
                          location={location}
                        />
                      ))}
                    </StyledListCategory>
                  ))}
                </StyledList>
                {!state?.leftEdgeSidebar?.collapsed && (
                  <SidebarFooter>
                    <Typography variant="body2" color="white">
                      &copy; Sports Gravy {new Date().getFullYear()}
                    </Typography>
                    <Typography variant="body2" color="white">
                      About Us | Help | Contact Us
                    </Typography>
                  </SidebarFooter>
                )}
              </SideBarContentContainer>
            </StyledSidebarContent>
          </EdgeSidebar>
          <ContentContainer
            className={state?.leftEdgeSidebar?.collapsed ? "collapsed" : ""}
          >
            <Outlet />
          </ContentContainer>
        </>
      )}
    </Root>
  );
};
